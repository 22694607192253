import firebase from 'firebase/app'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface BasicDataConverter<T> {
  fromDocument(id: string, document: firebase.firestore.DocumentData): T
}

export class Dictionary {
  constructor (
    public readonly id: string,
    public name: string,
    public members: string[],
    public owner: string,
    public modified: dayjs.Dayjs,
    public readonly numWords: number
  ) {}

  toString (): string {
    return `{ Dictionary ${this.id}: ${this.name}, with owner ${this.owner} & members ${this.members.join(', ')}, modified at ${this.modified}, containing ${this.numWords} words. }`
  }
}

class DictionaryConverter implements firebase.firestore.FirestoreDataConverter<Dictionary>, BasicDataConverter<Dictionary> {
  fromFirestore (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Dictionary {
    return this.fromDocument(snapshot.id, snapshot.data(options))
  }

  toFirestore (dictionary: Dictionary): firebase.firestore.DocumentData {
    return {
      name: dictionary.name,
      members: dictionary.members,
      owner: dictionary.owner,
      modified: firebase.firestore.Timestamp.fromDate(dictionary.modified.toDate())
    }
  }

  fromDocument(id: string, document: firebase.firestore.DocumentData): Dictionary {
    return new Dictionary(id, document.name, document.members, document.owner, dayjs(document.modified.toDate()).utc(true).tz(), document.num_words)
  }
}

export const dictionaryConverter = new DictionaryConverter()

export class DictionaryRef {
  constructor (
    public readonly id: string,
    public readonly ref: firebase.firestore.DocumentReference<Dictionary>,
    public readonly modified: dayjs.Dayjs
  ) {}

  toString (): string {
    return `{ Dictionary ref ${this.id}: to ${this.ref.id}, modified at ${this.modified}. }`
  }
}

class DictionaryRefConverter implements firebase.firestore.FirestoreDataConverter<DictionaryRef> {
  fromFirestore (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): DictionaryRef {
    const data = snapshot.data(options)
    return new DictionaryRef(snapshot.id, data.ref.withConverter(dictionaryConverter), dayjs(data.modified.toDate()).utc(true).tz())
  }

  toFirestore (dictionary: DictionaryRef): firebase.firestore.DocumentData {
    return {
      ref: dictionary.ref,
      modified: firebase.firestore.Timestamp.fromDate(dictionary.modified.toDate())
    }
  }
}

export const dictionaryRefConverter = new DictionaryRefConverter()
