import firebase from 'firebase/app'
import dayjs from 'dayjs'
import { firestore as db } from './firebase'
import { Dictionary, dictionaryConverter, DictionaryRef, dictionaryRefConverter } from './dictionary'

type RawQuery = firebase.firestore.Query<firebase.firestore.DocumentData>
type DictionaryQuery = firebase.firestore.Query<Dictionary>
type DictionaryRefQuery = firebase.firestore.Query<DictionaryRef>

const dictionaryCol: firebase.firestore.CollectionReference = db.collection('dictionaries')
const popularQuery: DictionaryQuery = sortupDictionary(dictionaryCol.where('public', '==', true))

let userDoc: firebase.firestore.DocumentReference | null = null
let myQuery: DictionaryQuery | undefined
let favoritesQuery: DictionaryRefQuery | undefined
let viewLaterQuery: DictionaryRefQuery | undefined

// Called any time user is changed
export function updateUser (user: firebase.User | null): void {
  // Set up user-specific stuff
  const uid = user?.uid
  userDoc = db && uid ? db.doc(`/users/${uid}`) : null
  myQuery = sortupDictionary(dictionaryCol.where('members', 'array-contains', uid))
  favoritesQuery = sortupRef(userDoc?.collection('favorites'))
  viewLaterQuery = sortupRef(userDoc?.collection('view_later'))
}

function sortupDictionary (q: RawQuery): DictionaryQuery {
  return q.orderBy('modified', 'desc').withConverter(dictionaryConverter)
}

function sortupRef (q?: RawQuery): DictionaryRefQuery | undefined {
  return q?.orderBy('modified', 'desc').withConverter(dictionaryRefConverter)
}

function paginate<T>(q?: firebase.firestore.Query<T>, limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): firebase.firestore.Query<T> | undefined {

  if (q === undefined) {
    return undefined
  } else {
    const sq = startAfter ? q.startAfter(startAfter) : q
    return limit ? sq.limit(limit) : sq
  }
}

// Get popular dictionaries. Not affected by user.
export function getPopular (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): DictionaryQuery | undefined {
  return paginate(popularQuery, limit, startAfter)
}

// My Own & Shared Dictionaries
// limit: the number of items to pull
export function getMyDictionaries (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): DictionaryQuery | undefined {
  return paginate(myQuery, limit, startAfter)
}

// Get view later list, requires hitting ref documents, then bouncing to real dictionaries.
export function getViewLater (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): DictionaryRefQuery | undefined {
  return paginate(viewLaterQuery, limit, startAfter)
}

// Get favorites list, requires hitting ref documents, then bouncing to real dictionaries.
export function getFavorites (limit?: number, startAfter?: firebase.firestore.DocumentSnapshot): DictionaryRefQuery | undefined {
  return paginate(favoritesQuery, limit, startAfter)
}

export function getDictionary (id: string): firebase.firestore.DocumentReference<Dictionary> | undefined {
  try {
    return dictionaryCol.doc(id).withConverter(dictionaryConverter)
  }
  catch (e) {
    return undefined
  }
}

export function isViewLater (dictionaryID: string): Promise<boolean> {
  return userDoc
    ? userDoc.collection("view_later").doc(dictionaryID).get().then(d => d.exists)
    : new Promise(resolve => resolve(false))
}

export function isFavorite (dictionaryID: string): Promise<boolean> {
  return userDoc
    ? userDoc.collection("favorites").doc(dictionaryID).get().then(d => d.exists)
    : new Promise(resolve => resolve(false))
}

// Update existance of ref in view_later, favorites, etc
function setRefState (dictionaryID: string, col: firebase.firestore.CollectionReference, inList: boolean) {
  const doc = col.doc(dictionaryID)
  console.log(doc.path)

  if (inList) {
    return doc?.set({
      ref: dictionaryCol.doc(dictionaryID),
      modified: firebase.firestore.FieldValue.serverTimestamp()
    })
  } else {
    return doc?.delete()
  }
}

export function setViewLater (dictionaryID: string, isViewLater: boolean): Promise<void> {
  if (userDoc) {
    return setRefState(dictionaryID, userDoc.collection("view_later"), isViewLater)
  } else {
    return new Promise(resolve => resolve())
  }
}

export function setFavorites (dictionaryID: string, isFavorite: boolean): Promise<void> {
  if (userDoc) {
    return setRefState(dictionaryID, userDoc.collection("favorites"), isFavorite)
  } else {
    return new Promise(resolve => resolve())
  }
}

export async function get<T>(query: firebase.firestore.Query<T>): Promise<T[]> {
  return (await query.get()).docs.map(d => d.data())
}

export function onSnapshot<T>(query: firebase.firestore.Query<T>, onSnap: (d: (T | undefined)[]) => void): void {

  query.onSnapshot(snap => {
    onSnap(snap.docs.map(doc => doc.data()))
  })
}

export async function getRef(ref: DictionaryRef): Promise<Dictionary> {
  return (await ref.ref.get()).data()
    ?? new Dictionary(ref.ref.id, "Unavailable", [], "", dayjs(), 0)
}

export function onSnapshotRef(query: firebase.firestore.Query<DictionaryRef>, onSnap: (d: (Dictionary | undefined)[]) => void): void {

  query.onSnapshot(snap => {
    const results: (Dictionary | undefined)[] = []

    snap.forEach(docref => {
      const i = results.length
      results.push(undefined)

      getRef(docref.data()).then(doc => {
        results[i] = doc

        // make sure dest updates with new array
        onSnap(results.slice())
      })
    })

    // push initial list of undefined
    onSnap(results)
  })
}
