import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import * as queries from '@/api/dictionaries'

// Always load error pages immediately.
import PageNotFoundErrorPage from '@/views/PageNotFoundError.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: { title: 'Home' }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/d/:id',
    name: 'view-dictionary',
    component: () => import(/* webpackChunkName: "dictionary" */ '@/views/ViewDictionary.vue'),
    meta: { title: (route: Route) => route.params.id }
  },
  {
    path: '/d/:id/gloss',
    name: 'gloss-dictionary',
    component: () => import(/* webpackChunkName: "gloss" */ '@/views/ViewDictionary.vue'),
    meta: { title: (route: Route) => route.params.id }
  },
  {
    path: '/d/:id/rules',
    name: 'rule-dictionary',
    component: () => import(/* webpackChunkName: "rule" */ '@/views/ViewDictionary.vue'),
    meta: { title: (route: Route) => route.params.id }
  },
  {
    path: '/d/:id/settings',
    name: 'setup-dictionary',
    component: () => import(/* webpackChunkName: "rule" */ '@/views/ViewDictionary.vue'),
    meta: { title: (route: Route) => route.params.id }
  },
  {
    path: '/my',
    name: 'my-dictionaries',
    component: () => import(/* webpackChunkName: "dlist" */ '@/views/DictionaryList.vue'),
    meta: { title: 'My Own & Shared' },
    props: {
      listProvider: queries.getMyDictionaries,
      listType: 'dynamic',
      label: 'My Own & Shared Dictionaries'
    }
  },
  {
    path: '/later',
    name: 'later-dictionaries',
    component: () => import(/* webpackChunkName: "dlist" */ '@/views/DictionaryList.vue'),
    meta: { title: 'View Later' },
    props: {
      listProvider: queries.getViewLater,
      listType: 'refs',
      label: 'View Later'
    }
  },
  {
    path: '/favorite',
    name: 'favorite-dictionaries',
    component: () => import(/* webpackChunkName: "dlist" */ '@/views/DictionaryList.vue'),
    meta: { title: 'Favorites' },
    props: {
      listProvider: queries.getFavorites,
      listType: 'refs',
      label: 'Favorite Dictionaries'
    }
  },
  {
    path: '/top',
    name: 'top-dictionaries',
    component: () => import(/* webpackChunkName: "dlist" */ '@/views/DictionaryList.vue'),
    meta: { title: 'Popular' },
    props: {
      listProvider: queries.getPopular,
      listType: 'static',
      label: 'Popular Dictionaries'
    }
  },
  {
    path: '*',
    component: PageNotFoundErrorPage,
    meta: { title: 'Error 404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
