import firebase from 'firebase/app'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { BasicDataConverter } from './dictionary'

dayjs.extend(utc)
dayjs.extend(timezone)

interface PropertyMap {
  [key: string]: string;
}

export class Word {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public properties: PropertyMap,
    public modified: dayjs.Dayjs
  ) {}

  toString(): string {
    return `{ Word ${this.name}: ${this.properties} }`
  }
}

class WordConverter implements firebase.firestore.FirestoreDataConverter<Word>, BasicDataConverter<Word> {
  fromFirestore (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Word {
    return this.fromDocument(snapshot.id, snapshot.data(options))
  }

  toFirestore (word: Word): firebase.firestore.DocumentData {
    return {
      name: word.name,
      properties: word.properties,
      modified: firebase.firestore.Timestamp.fromDate(word.modified.toDate())
    }
  }

  fromDocument(id: string, document: firebase.firestore.DocumentData): Word {
    return new Word(id, document.name, document.properties, dayjs(document.modified.toDate()).utc(true).tz())
  }
}

export const wordConverter = new WordConverter()
