








import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { searchText, SearchResult } from '@/api/textSearch'
// import firebase from 'firebase/app'

@Component({
})
export default class AppSearch extends Vue {
  private loading = false
  private items: Promise<SearchResult>[] = []
  private search: string | null = null
  private selection: string | null = null

  @Prop(Boolean) readonly dense?: boolean

  @Watch('search')
  updateSearch (value: string): void {
    value && value !== this.selection && this.query(value)
  }

  query (value: string): void {
    this.loading = true

    // run the query
    searchText(value, 10).then(results => {
      this.items = results
      this.loading = false
    })
  }
}

