import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyAOTJEBOkBSjkScMsYjHTT-eVckwgah6sc',
  authDomain: 'lexicl.net',
  databaseURL: 'https://lexicl.firebaseio.com',
  projectId: 'lexicl',
  storageBucket: 'lexicl.appspot.com',
  messagingSenderId: '763808698202',
  appId: '1:763808698202:web:c3b5f75ed42497477ded63',
  measurementId: 'G-1SF3J7NZ0S'
})
export const analytics = firebase.analytics()
export const auth = firebase.auth()
export const firestore = firebase.firestore()
