
























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import firebase from 'firebase/app'
import { auth } from '@/api/firebase'
import { updateUser } from '@/api/dictionaries'
import setTitle from '@/api/title'
import AppSearch from '@/components/AppSearch.vue'

@Component({
  components: {
    'app-search': AppSearch
  }
})
export default class App extends Vue {
  private user: firebase.User | null = null
  private drawer = false
  private barDense = false

  private signedOutMenu = [
    { title: 'Sign in', icon: 'mdi-login', click: () => this.signin() }
  ]

  private signedInMenu = [
    { title: 'Switch User', icon: 'mdi-account-switch', click: () => this.signin() },
    { title: 'Sign out', icon: 'mdi-logout', click: () => this.signout() }
  ]

  private moreMenu = [
    { title: 'About', icon: 'mdi-information', to: { name: 'about' } }
  ]

  private navItems = [
    { title: 'Home', icon: 'mdi-home', to: { name: 'home' } },
    { divider: true },
    { title: 'Popular', icon: 'mdi-folder-star', to: { name: 'top-dictionaries' } }
  ]

  private userNavItems = [
    { title: 'My Own & Shared', icon: 'mdi-folder-account', to: { name: 'my-dictionaries' } },
    { title: 'Favorites', icon: 'mdi-folder-heart', to: { name: 'favorite-dictionaries' } },
    { title: 'View Later', icon: 'mdi-folder-clock', to: { name: 'later-dictionaries' } }
  ]

  created (): void {
    auth.onAuthStateChanged(user => {
      this.user = user
      updateUser(user)
    })
  }

  signin (): void {
    var provider = new firebase.auth.GoogleAuthProvider()
    auth.signInWithRedirect(provider)
  }

  signout (): void {
    auth.signOut()
  }

  updateTitle (r: Route): void {
    const record = r.matched[0]
    if (record) {
      const title = record.meta.title ?? record.name ?? r.path
      setTitle((typeof title === 'function') ? title(r) : title)
    }
  }

  mounted (): void {
    this.updateTitle(this.$route)
  }

  @Watch('$route')
  watchRoute (to: Route): void {
    this.updateTitle(to)
  }

  onScroll (): void {
    this.barDense = window.scrollY > 48
  }
}
