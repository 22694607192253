import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00aa88',
        secondary: '#0088aa',
        accent: '#00d4aa'
      }
    }
  }
})
