import firebase from 'firebase/app'
import { firestore as db } from './firebase'
import { Dictionary, dictionaryConverter } from './dictionary'
import { Word, wordConverter } from './word'

export type SearchResult = Dictionary | Word | undefined

export function isDictionary(value: SearchResult): value is Dictionary {
  return (value as Dictionary).members !== undefined;
}

export function isWord(value: SearchResult): value is Word {
  return (value as Word).properties !== undefined;
}

export function searchText(text: string, limit: number): Promise<Promise<SearchResult>[]> {
  return search_terms(text.split(' ').filter(t => t.length > 0), limit)
}

async function convertAny(ref: firebase.firestore.DocumentReference | null): Promise<SearchResult | undefined> {
  if (!ref) {
    return undefined
  }
  const data = (await ref.get()).data()
  if (!data) {
    return undefined
  }

  if (data.members !== undefined) {
    return dictionaryConverter.fromDocument(ref.id, data)
  } else if (data.properties !== undefined) {
    return wordConverter.fromDocument(ref.id, data)
  } else {
    return undefined
  }
}

export async function search_terms(terms: string[], limit: number): Promise<Promise<SearchResult>[]> {
  let q: firebase.firestore.Query = db.collectionGroup("search_groups")

  terms.forEach(t => {
    q = q.where("terms", "array-contains", t)
  })

  return (await q.orderBy("rank", "desc").limit(limit)
    // Grab the actual document being searched
    .get()).docs.map(doc => convertAny(doc.ref.parent.parent))
}
